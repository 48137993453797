import { default as _404AIZrvyfgegMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/404.vue?macro=true";
import { default as _500zAimDWsX2iMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/500.vue?macro=true";
import { default as forgot_45passwordjWQR6idK33Meta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/forgot-password.vue?macro=true";
import { default as register_45with_45otpWpd2VtBJwhMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/register-with-otp.vue?macro=true";
import { default as reset_45passwordvoz82sLkSeMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/reset-password.vue?macro=true";
import { default as sign_45inbZFvr7FaEmMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/sign-in.vue?macro=true";
import { default as auth1T5krVLnNfMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth.vue?macro=true";
import { default as _91id_9369l5lrne3SMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking-v2/[id].vue?macro=true";
import { default as indexDAmra3PoGBMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking-v2/index.vue?macro=true";
import { default as _91id_93yWS6aLZ6L2Meta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking/[id].vue?macro=true";
import { default as indexBHUhchhNuMMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking/index.vue?macro=true";
import { default as _91clinicId_93ITffJ1zzbNMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/clinic/[clinicId].vue?macro=true";
import { default as indexB9zBNJSw8rMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/clinic/index.vue?macro=true";
import { default as dashboardhUyO71pkb4Meta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/dashboard.vue?macro=true";
import { default as _91id_93uKHLPtj8bmMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/doctor/[id].vue?macro=true";
import { default as indexyTddSViXiXMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/index.vue?macro=true";
import { default as maintenance1BypIDCW8CMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/maintenance.vue?macro=true";
import { default as _91id_9307Z5mDdRjyMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/[id].vue?macro=true";
import { default as addDUMgvRxTdXMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/add.vue?macro=true";
import { default as indexZM2gvn3JZyMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/index.vue?macro=true";
import { default as _91slug_93h0oxdRehm8Meta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/tin-tuc/[slug].vue?macro=true";
import { default as indexs4EdfVNcUGMeta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/tin-tuc/index.vue?macro=true";
import { default as _91id_93Co8lszAaB1Meta } from "/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/wv/question/[id].vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404AIZrvyfgegMeta || {},
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500zAimDWsX2iMeta || {},
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: auth1T5krVLnNfMeta || {},
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-register-with-otp",
    path: "register-with-otp",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/register-with-otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "reset-password",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-sign-in",
    path: "sign-in",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/auth/sign-in.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "booking-v2-id",
    path: "/booking-v2/:id()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking-v2/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking-v2",
    path: "/booking-v2",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking-v2/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-id",
    path: "/booking/:id()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "clinic-clinicId",
    path: "/clinic/:clinicId()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/clinic/[clinicId].vue").then(m => m.default || m)
  },
  {
    name: "clinic",
    path: "/clinic",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/clinic/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "doctor-id",
    path: "/doctor/:id()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexyTddSViXiXMeta || {},
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance1BypIDCW8CMeta || {},
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "question-id",
    path: "/question/:id()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: "question-add",
    path: "/question/add",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/add.vue").then(m => m.default || m)
  },
  {
    name: "question",
    path: "/question",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc-slug",
    path: "/tin-tuc/:slug()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/tin-tuc/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tin-tuc",
    path: "/tin-tuc",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: "wv-question-id",
    path: "/wv/question/:id()",
    component: () => import("/home/anh/projects/outsource/alobacsi/booking/web-user/src/pages/wv/question/[id].vue").then(m => m.default || m)
  }
]